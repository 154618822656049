<template>
  <div>
    <div class="flex flex-col w-full py-2 text-center">
      <h1 class="text-2xl font-bold text-gray-700 sm:text-3xl">
        Contacto
      </h1>
    </div>
    <div>
      <div class="flex flex-col py-2 text-left">
        <el-form
          ref="contactUsForm"
          :model="contactUsForm"
          :rules="contactUsRules"
          hide-required-asterisk>
          <el-form-item labele="Correo Electrónico">
            <el-input v-model="contactUsForm.email" disabled />
          </el-form-item>
          <el-form-item label="Asunto">
            <el-input v-model="contactUsForm.issue" />
          </el-form-item>
          <el-form-item label="">
            <el-input type="textarea" minRows="4" v-model="contactUsForm.body" />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <sa-button
          class="rounded text-bold"
          custom-class="w-full"
          type="primary"
          v-loading="loading"
          element-loading-background="#DDD6FE"
          element-loading-spiner=""
          @click="send">
          <i class="bx bx-send"></i>&nbsp;Enviar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContactUsForm',
  data() {
    return {
      loading: false,
      contactUsForm: {
        email: 'servicioalcliente@samiiweb.com',
        issue: '',
        body: '',
      },
      contactUsRules: {
        email: [{ required: true, type: 'email', message: 'Captura tu email', trigger: 'change' }],
        issue: [{ required: true, message: 'Captura el asunto', trigger: 'change' }],
        body: [{ required: true, message: 'Captura los detalles', trigger: 'change' }],
      },
    }
  },
  methods: {
    async send() {
      this.$refs['contactUsForm'].validate(async (isFormValid) => {
        if (!isFormValid) {
          return false;
        }

        const mailtoLink = `mailto:${encodeURIComponent(this.contactUsForm.email)}?subject=${encodeURIComponent(this.contactUsForm.issue)}&body=${encodeURIComponent(this.contactUsForm.body)}`;

        window.location.href = mailtoLink;
      });
    }
  }
}
</script>